<template>
  <div class="pager" :class="{'small': small }">
    <span class="section">
      {{ from }} to {{ to }} of {{ sourceData.length }}
    </span>
    <span class="section">
      <span class='pager-icon first' @click="firstPage" :class="{ 'disabled': !hasPreviousPage() }"></span>
      <span class='pager-icon previous' @click="previousPage" :class="{ 'disabled': !hasPreviousPage() }"></span>

      <span>Page {{ page+1 }} of {{ pageCount() }}</span>

      <span class='pager-icon next' @click="nextPage" :class="{ 'disabled': !hasNextPage() }"></span>
      <span class='pager-icon last' @click="lastPage" :class="{ 'disabled': !hasNextPage() }"></span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Pager',
  props: {
    pageSize: { type: Number, default: 25 },
    sourceData: { type: Array, default: function() { return [] } },
    small: { type: Boolean, default: false },
    reset: { type: Boolean, default: false }
  },
  data() {
    return {
      page: 0
    }
  },
  computed: {
    from() {
      return (this.page * this.pageSize) + 1;
    },
    to() {
      return Math.min((this.page * this.pageSize) + this.pageSize, this.sourceData.length);
    }
  },
  watch: {
    reset(value) {
      if (value) {
        this.firstPage();
        this.$emit('restored');
      }
    }
  },
  methods: {
    hasPreviousPage() {
      return this.page > 0;
    },
    hasNextPage() {
      return (this.page * this.pageSize) + this.pageSize <= this.sourceData.length;
    },
    pageCount() {
      return Math.ceil(this.sourceData.length / this.pageSize);
    },
    firstPage() {
      if (this.hasPreviousPage()) {
        this.page = 0;
        this.$emit('paged', this.page);
      }
    },
    previousPage() {
      if (this.hasPreviousPage()) {
        this.page--;
        this.$emit('paged', this.page);
      }
    },
    nextPage() {
      if (this.hasNextPage()) {
        this.page++;
        this.$emit('paged', this.page);
      }
    },
    lastPage() {
      if (this.hasNextPage()) {
        this.page = this.pageCount()-1;
        this.$emit('paged', this.page);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.pager {
  border-top: 1px solid;
  border-color: var(--ag-border-color);
  color: var(--ag-secondary-foreground-color);
  font-size: 12px;
  line-height: normal;
  min-height: 32px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  user-select: none;
}
.pager.small {
  font-size: 11px;
  flex-direction: column;
  justify-content: center;
}

.section {
  margin: 0 12px;
  display: flex;
  align-items: center;
}
.section > * {
  margin: 0 4px;
}
.pager-icon {
  font-family: "agGridBalham";
  font-size: 16px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  cursor: pointer;
}

.pager-icon.first::before {
	content: "\f116";
}
.pager-icon.previous::before {
	content: "\f126";
}
.pager-icon.next::before {
	content: "\f120";
}
.pager-icon.last::before {
	content: "\f119";
}

.pager-icon.disabled {
  color: var(--ag-disabled-foreground-color);
  cursor: default;
}

</style>
