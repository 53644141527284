var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex" }, [
    _vm.item !== null
      ? _c(
          "div",
          { staticClass: "d-flex" },
          [
            _vm.item.operator !== "within" &&
            _vm.item.operator !== "without" &&
            _vm.item.operator !== "inside" &&
            _vm.item.fieldtype === "Enum" &&
            !(
              typeof _vm.item.agFunc !== "undefined" &&
              _vm.item.agFunc.applyFunction
            )
              ? _c("b-form-select", {
                  staticClass: "ml-1 w-auto",
                  attrs: { options: _vm.getOptions(_vm.item.field) },
                  model: {
                    value: _vm.valueDisplay,
                    callback: function ($$v) {
                      _vm.valueDisplay = $$v
                    },
                    expression: "valueDisplay",
                  },
                })
              : _vm._e(),
            _vm.item.operator === "within" ||
            _vm.item.operator === "without" ||
            (_vm.item.operator === "inside" &&
              _vm.item.fieldtype === "String") ||
            ((_vm.item.fieldtype === "String" ||
              _vm.item.fieldtype === "Character" ||
              _vm.item.fieldtype === "List<KindData>" ||
              _vm.item.fieldtype === "List<String>") &&
              !(
                typeof _vm.item.agFunc !== "undefined" &&
                _vm.item.agFunc.applyFunction
              ))
              ? _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("b-form-input", {
                      attrs: {
                        size: "md",
                        readonly: _vm.disabled,
                        placeholder: _vm.placeholder,
                      },
                      model: {
                        value: _vm.stringValueDisplay,
                        callback: function ($$v) {
                          _vm.stringValueDisplay = $$v
                        },
                        expression: "stringValueDisplay",
                      },
                    }),
                    _vm.item.operator === "between" ||
                    _vm.item.operator === "outside" ||
                    _vm.item.operator === "inside"
                      ? _c("b-form-input", {
                          staticClass: "ml-1",
                          attrs: {
                            size: "md",
                            readonly: _vm.disabled,
                            placeholder: _vm.$t("dataview.value"),
                          },
                          model: {
                            value: _vm.stringValueDisplay2,
                            callback: function ($$v) {
                              _vm.stringValueDisplay2 = $$v
                            },
                            expression: "stringValueDisplay2",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.item.operator !== "within" &&
            (_vm.item.fieldtype === "Float" ||
              _vm.item.fieldtype === "Integer" ||
              _vm.item.fieldtype === "Double" ||
              _vm.item.fieldtype === "Long" ||
              (typeof _vm.item.agFunc !== "undefined" &&
                _vm.item.agFunc.applyFunction))
              ? _c(
                  "div",
                  { staticClass: "d-flex maxwidth" },
                  [
                    _c("b-form-input", {
                      attrs: {
                        size: "md",
                        type: "number",
                        min: _vm.min,
                        max: _vm.max,
                        readonly: _vm.disabled,
                        placeholder: _vm.placeholder,
                      },
                      model: {
                        value: _vm.valueDisplay,
                        callback: function ($$v) {
                          _vm.valueDisplay = $$v
                        },
                        expression: "valueDisplay",
                      },
                    }),
                    _vm.item.operator === "between" ||
                    _vm.item.operator === "outside" ||
                    _vm.item.operator === "inside"
                      ? _c("b-form-input", {
                          staticClass: "ml-1",
                          attrs: {
                            size: "md",
                            type: "number",
                            min: _vm.min,
                            max: _vm.max,
                            readonly: _vm.disabled,
                            placeholder: _vm.$t("dataview.value"),
                          },
                          model: {
                            value: _vm.valueDisplay2,
                            callback: function ($$v) {
                              _vm.valueDisplay2 = $$v
                            },
                            expression: "valueDisplay2",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.item.fieldtype === "Boolean" &&
            !(
              typeof _vm.item.agFunc !== "undefined" &&
              _vm.item.agFunc.applyFunction
            )
              ? _c(
                  "div",
                  { staticClass: "d-flex filter-radio-buttons" },
                  [
                    _c("b-form-radio-group", {
                      staticClass: "ml-1 radio-group-class",
                      attrs: {
                        size: "sm",
                        disabled: _vm.disabled,
                        options: _vm.boolOptions,
                      },
                      model: {
                        value: _vm.valueDisplay,
                        callback: function ($$v) {
                          _vm.valueDisplay = $$v
                        },
                        expression: "valueDisplay",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.item.fieldtype === "Date" &&
            _vm.item.operator !== "within" &&
            _vm.item.operator !== "without" &&
            (typeof _vm.item.agFunc === "undefined" ||
              !_vm.item.agFunc.applyFunction)
              ? _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    !_vm.useToday
                      ? _c("b-form-datepicker", {
                          staticClass: "date-height",
                          attrs: {
                            "today-button": "",
                            "reset-button": "",
                            "close-button": "",
                            "hide-header": "",
                            "label-today-button": _vm.$t("date.today"),
                            "label-reset-button": _vm.$t("date.reset"),
                            "label-close-button": _vm.$t("date.close"),
                            "today-button-variant": "primary",
                            "reset-button-variant": "danger",
                            "close-button-variant": "secondary",
                            disabled: _vm.disabled,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function (ref) {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "calendar-days"] },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2621928167
                          ),
                          model: {
                            value: _vm.item.value1,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "value1", $$v)
                            },
                            expression: "item.value1",
                          },
                        })
                      : _vm._e(),
                    (!_vm.useToday && _vm.item.operator === "between") ||
                    _vm.item.operator === "outside" ||
                    _vm.item.operator === "inside"
                      ? _c("b-form-datepicker", {
                          staticClass: "ml-1 date-height",
                          attrs: {
                            "today-button": "",
                            "reset-button": "",
                            "close-button": "",
                            "hide-header": "",
                            "label-today-button": _vm.$t("date.today"),
                            "label-reset-button": _vm.$t("date.reset"),
                            "label-close-button": _vm.$t("date.close"),
                            "today-button-variant": "primary",
                            "reset-button-variant": "danger",
                            "close-button-variant": "secondary",
                            disabled: _vm.disabled,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function (ref) {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "calendar-days"] },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2621928167
                          ),
                          model: {
                            value: _vm.item.value2,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "value2", $$v)
                            },
                            expression: "item.value2",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.item.fieldtype === "Time" &&
            _vm.item.operator !== "within" &&
            _vm.item.operator !== "without" &&
            (typeof _vm.item.agFunc === "undefined" ||
              !_vm.item.agFunc.applyFunction)
              ? _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    !_vm.useToday
                      ? _c("b-form-timepicker", {
                          staticClass: "date-height",
                          attrs: {
                            "today-button": "",
                            "reset-button": "",
                            "close-button": "",
                            "hide-header": "",
                            "label-today-button": _vm.$t("date.today"),
                            "label-reset-button": _vm.$t("date.reset"),
                            "label-close-button": _vm.$t("date.close"),
                            "today-button-variant": "primary",
                            "reset-button-variant": "danger",
                            "close-button-variant": "secondary",
                            disabled: _vm.disabled,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function (ref) {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "clock"] },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2592694975
                          ),
                          model: {
                            value: _vm.timeDisplay,
                            callback: function ($$v) {
                              _vm.timeDisplay = $$v
                            },
                            expression: "timeDisplay",
                          },
                        })
                      : _vm._e(),
                    (!_vm.useToday && _vm.item.operator === "between") ||
                    _vm.item.operator === "outside" ||
                    _vm.item.operator === "inside"
                      ? _c("b-form-timepicker", {
                          staticClass: "ml-1 date-height",
                          attrs: {
                            "today-button": "",
                            "reset-button": "",
                            "close-button": "",
                            "hide-header": "",
                            "label-today-button": _vm.$t("date.today"),
                            "label-reset-button": _vm.$t("date.reset"),
                            "label-close-button": _vm.$t("date.close"),
                            "today-button-variant": "primary",
                            "reset-button-variant": "danger",
                            "close-button-variant": "secondary",
                            disabled: _vm.disabled,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function (ref) {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "clock"] },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2592694975
                          ),
                          model: {
                            value: _vm.timeDisplay2,
                            callback: function ($$v) {
                              _vm.timeDisplay2 = $$v
                            },
                            expression: "timeDisplay2",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.item.fieldtype === "Date" &&
            _vm.item.operator !== "within" &&
            _vm.item.operator !== "without" &&
            (typeof _vm.item.agFunc === "undefined" ||
              !_vm.item.agFunc.applyFunction)
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center today-check-container",
                  },
                  [
                    _vm.item.operator !== "between" &&
                    _vm.item.operator !== "outside" &&
                    _vm.item.operator !== "inside"
                      ? _c(
                          "b-form-checkbox",
                          {
                            staticClass: "today-check",
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value: _vm.useToday,
                              callback: function ($$v) {
                                _vm.useToday = $$v
                              },
                              expression: "useToday",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("use_todays_date")))]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }