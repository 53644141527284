var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pager", class: { small: _vm.small } }, [
    _c("span", { staticClass: "section" }, [
      _vm._v(
        " " +
          _vm._s(_vm.from) +
          " to " +
          _vm._s(_vm.to) +
          " of " +
          _vm._s(_vm.sourceData.length) +
          " "
      ),
    ]),
    _c("span", { staticClass: "section" }, [
      _c("span", {
        staticClass: "pager-icon first",
        class: { disabled: !_vm.hasPreviousPage() },
        on: { click: _vm.firstPage },
      }),
      _c("span", {
        staticClass: "pager-icon previous",
        class: { disabled: !_vm.hasPreviousPage() },
        on: { click: _vm.previousPage },
      }),
      _c("span", [
        _vm._v(
          "Page " + _vm._s(_vm.page + 1) + " of " + _vm._s(_vm.pageCount())
        ),
      ]),
      _c("span", {
        staticClass: "pager-icon next",
        class: { disabled: !_vm.hasNextPage() },
        on: { click: _vm.nextPage },
      }),
      _c("span", {
        staticClass: "pager-icon last",
        class: { disabled: !_vm.hasNextPage() },
        on: { click: _vm.lastPage },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }